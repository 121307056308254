import React from "react";
import {Col, Row, Container} from "react-bootstrap";
import { HeroSectionWrapper } from "./HeroSection.styles"
import ApButton from "../../Global/Common/ApButton/ApButton";
import HeroImage from "./HeroImage"
import { LINKS } from "../../../data/pageLinks.data";
import { Link } from "gatsby"

const HeroSection = () => (

    <HeroSectionWrapper className="px-3 pb-5">
        <Container className="d-flex flex-column">
            <Row className="text-lg-center m-0">
                <Col xs={12} className="mb-5 pr-0">
                    <h1 className="Ambit primaryBlue font-weight-bold mx-auto text-lg-center mb-4">Utilise our Partnership Network</h1>
                    <p className="mx-auto text-intro w-100">Grow your business and connect to several enterprise level merchants through a single integration.</p>
                    <Link to={LINKS.contactUs}>
                        <ApButton className="font-weight-bold mx-auto mt-4"
                            variant="lightGreen"
                        >
                            Become an APEXX Partner
                        </ApButton>
                    </Link>
                </Col>
            </Row>
        </Container>
        <div className="text-center hero-image-container mt-5">
            <HeroImage/>
        </div>
    </HeroSectionWrapper>
);

export default HeroSection