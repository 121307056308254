import styled from "styled-components";


export const OurPartnerPackageWrapper = styled.section`
    background: linear-gradient(180.34deg, #144056 1.48%, #428AC9 140.7%);

    .intro-text{
        font-size: 1.25rem;
    }

    .key-points{
        p{
            max-width: 340px;
            font-size: 1.125rem;
        }
    }

    .lead-image{
        margin: auto;
        display: block;
        position: relative;
        top: -50px;
        max-width: 100%;
    }
`