import styled from "styled-components";

export const HeroSectionWrapper = styled.section` 
    background: linear-gradient(182.37deg, #FFFFFF 37.05%, #8FC5EA 199.57%);
    height: auto;
    max-height: 1100px;

    .container{
        padding-top: 150px;

        h1{
            font-size: 2.6rem;
        }

        .text-intro{
            font-size: 1.25rem;
        }

        .btn-lightGreen{
            border-radius: 15px;
            @media (max-width: 992px) {
                padding: 0.375em 1.25em;
            }
        }

        @media (max-width: 991px){
            padding-top: 50px;
        }        
    }
    .hero-image-container{
        @media (max-width: 1050px) {
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            width: 992px;
        }
    }
    .hero-image{
        max-width: 100%;
        max-height: 600px;
        min-width: 992px;
        margin: auto;
        text-align: center;
        animation-name: levitate;
        animation-duration: 2.5s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out ease-in-out;
    }


    @keyframes levitate {
        from {
            -webkit-transform: translateY(0px);
        }
        45% {
            -webkit-transform: translateY(20px);
        }
        to {
            -webkit-transform: translateY(0px);
        }
    }
`