import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image"

const HeroImage = () => {
    const data = useStaticQuery(graphql`
        {
            allFile(filter: {relativeDirectory: {eq: "partnerships/HeroSection"}, name: {eq: "HeroImage"}}) {
                edges {
                node {
                    childImageSharp {
                    fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
                }
            }
        }
    `)

    return <img 
    src={data.allFile.edges[0] && data.allFile.edges[0].node.childImageSharp.fluid.src} 
    alt="heroImage"
    className="hero-image"
    />

}

export default HeroImage;