import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ReadyToBecomeAPartnerWrapper } from "./ReadyToBecomeAPartner.styles";
import { LINKS } from "../../../data/pageLinks.data";
import { Link } from "gatsby"
import HubspotPartnershipsForm from "../../ContactUsPage/ContactForm/HubspotPartnershipsForm";

const ReadyToBecomeAPartner = () => (
    <ReadyToBecomeAPartnerWrapper className="mb-md-5 px-3">
        <Container className="bg-white p-lg-5 py-5">
            <Row className="mt-md-5 px-3 px-lg-0 mx-0">
                <Col lg={5} className="intro-text">
                    <h2 className="h1 font-weight-bold ambit primary-blue-text mb-5">Ready to become a partner?</h2>
                    <p className="pb-3">If becoming an APEXX partner sounds like a good fit for you, please register your interest and a member of our team will be in touch shortly.</p>
                    <p className="pb-3 font-weight-bold">Partnership Types:</p>
                    <p className="pb-3">
                        <ul className="p-0 mb-0">
                            <li>Platform (Aggregator/Acquirer) Partners</li>
                            <li>Buy Now Pay Later (BNPL) Partners</li>
                            <li>Channel (Referral) Partners</li>
                        </ul>
                    </p>
                    <p>For any queries outside of partnerships please contact <a href="mailto:sales@apexx.global" target="_blank">sales@apexx.global</a>
                    </p>
                </Col>
                <Col lg={7} className="d-block">
                    <h2 className="h1 contact-form-heading py-4 secondary-blue-2-text">
                        Contact our team
                    </h2>
                    <p className="pb-4 pb-lg-5">
                        Please fill in the form provided on this page and we’ll get back
                        to you as soon as possible. For press releases, company
                        information, media resources and more, you can visit our dedicated{" "}
                        <Link className="secondary-blue-2-text" to={LINKS.pressAndMedia}>press page</Link>.
                    </p>
                    <HubspotPartnershipsForm />
                </Col>
            </Row>
        </Container>
    </ReadyToBecomeAPartnerWrapper>
);

export default ReadyToBecomeAPartner