import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { SideBarWrapper } from "./SideBar.styles"
import { Button, Card } from "react-bootstrap"
import { LINKS } from "../../../data/pageLinks.data"


const DATA = {
  0: {
    title: "Contact us via mail",
    body: "If you wish to write us an email instead please use.",
    link: { href: "mailto:sales@apexx.global", text: "sales@apexx.global" },
  },
  1: {
    title: "Tech Support",
    body: "We're here to answer your technical questions whenever you need.",
    link: { href: LINKS.support, text: "- Go to support" },
  },
}

const SideBar = () => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          relativeDirectory: { eq: "contactUs" }
          name: { in: ["Mail", "Support"] }
        }
        sort: { fields: name, order: ASC }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const images = data.allFile.edges

  return (
    <SideBarWrapper className="d-flex flex-column justify-content-center h-100 align-items-center">
      {images.map(({ node }, idx) => {
        return (
          <Card
            style={{ width: "18rem" }}
            key={node.id}
            className="border-0 mb-4"
          >
            <Img
              fluid={node.childImageSharp.fluid}
              className="contact-image ml-3 "
            />
            <Card.Body>
              <Card.Title className="primary-blue-text font-weight-bold card-title">
                {DATA[idx].title}
              </Card.Title>
              <Card.Text>{DATA[idx].body}</Card.Text>
              <Button
                variant="link"
                as="a"
                href={DATA[idx].link.href}
                className="pl-0 pt-0 secondary-blue-2-text"
              >
                {DATA[idx].link.text}
              </Button>
            </Card.Body>
          </Card>
        )
      })}
    </SideBarWrapper>
  )
}

export default SideBar
