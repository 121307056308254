import styled from "styled-components";

export const HowPartnersHelpWrapper = styled.section`

    p.intro-text{
        font-size: 1.25rem;
    }

    .key-points{

        h3{
            span{
                display: inline-block;
                &::after {
                    content: '';
                    height: 15px;
                    width: 100%;
                    background-color: #6BE2E5;
                    opacity: 0.3;
                    display: block;
                    position: relative;
                    top: -11px;
                    z-index: -1;
                }
            }
        }
    }

    .help-image{
        position: relative;
        left: 100px;
        max-width: 100%;
        position: relative;
        max-height: 660px;
        @media (max-width: 991px) {
            left: 25px;
            max-width: 425px;
        }
    }
`