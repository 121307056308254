import React from "react";
import {Col, Row, Container} from "react-bootstrap"
import { HowPartnersHelpWrapper } from "./HowPartnersHelp.styles";
import HowPartnershipsHelpImage from "./HowPartnershipsHelpImage";

const HowPartnersHelp = () =>(
    <HowPartnersHelpWrapper className="px-3">
        <Container className="my-md-5 py-5">
            <Row className="mx-0">
                <Col xs={12} lg={8}>
                    <h2 className="h1 ambit font-weight-bold primary-blue-text">
                        Adding value to partners  at every stage
                    </h2>
                </Col>
                <Col xs={12} lg={9}>
                    <p className="intro-text my-4">
                    APEXX is on a mission to eliminate the stresses of getting paid and our partners add an immense
                    value to our strategy. We pride ourselves in building enduring relationships with the leading
                    aggregators, acquirers, BNPL providers, and Channel solutions to offer merchants bespoke payment
                    services.
                    </p>
                </Col>
            </Row>
            <Row className="mx-0 mt-4 pt-2 key-points">
                <Col xs={12} md={6} lg={4} xl={5}>
                    <div className="pb-4">
                        <h3 className="h5 ambit font-weight-bold primary-blue-text"><span>Speed to Market</span></h3>
                        <p className="pt-2 pb-3">
                            Directly integrate to our platform and instantly collect enterprise level merchant traffic through your acquiring channels.
                        </p>                    
                    </div>
                    <div className="pb-4">
                        <h3 className="h5 ambit font-weight-bold primary-blue-text"><span>Driving your Profits</span></h3>
                        <p className="pt-2 pb-3">
                            Take the hassle out of generating new custom as we do the heavy lifting for you.
                        </p>                    
                    </div>
                    <div className="pb-4">
                        <h3 className="h5 ambit font-weight-bold primary-blue-text"><span>Dedicated Resource</span></h3>
                        <p className="pt-2 pb-3">
                            Develop a strategy together with our experienced Partnerships team at your disposal.
                        </p>                    
                    </div>
                    <div>
                        <h3 className="h5 ambit font-weight-bold primary-blue-text"><span>Expanding your Potential</span></h3>
                        <p className="pt-2 pb-0 mb-3">
                            Promote your business against reputable brands as we scale internationally hand in hand. 
                        </p>                    
                    </div>
                </Col>
                <Col xs={12} md={6} lg={8} xl={7} className="d-none d-md-block">
                    <HowPartnershipsHelpImage/>
                </Col>
            </Row>
        </Container>
    </HowPartnersHelpWrapper>
);

export default HowPartnersHelp