import styled from "styled-components";

export const ReadyToBecomeAPartnerWrapper = styled.section`
    
    .container{
        position: relative;
        top: -100px;
        width: 100%;
        margin-bottom: -100px;
        .intro-text{
            font-size: 1.125rem;
            ul{
                list-style: none;
                font-weight: 500;
                li{
                    position: relative;
                    &::before{
                        content: ""; 
                        background-color: #6BE2E5;
                        height: 8px;
                        width: 8px;
                        border-radius: 8px;
                        display: inline-block;
                        position: absolute;
                        left: -20px;
                        top: 7px;
                    }
                }
            }
            p{
                a{
                    color: #4CB9EF;
                    font-weight: 500;
                }
            }
        }

        @media (max-width: 991px){
            top: auto;
            margin-bottom: auto;
        }
    }
`