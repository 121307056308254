import React from "react";
import {Col, Row, Container} from "react-bootstrap";
import { PartnerNotASupplierWrapper } from "./PartnerNotASupplier.styles";
import { graphql, useStaticQuery } from "gatsby";

const PartnerNotASupplier = () => {

    const data = useStaticQuery(graphql`
        {
            allFile(filter: {relativeDirectory: {eq: "partnerships/PartnerNotASupplier"}}) {
                edges {
                node {
                    name
                    childImageSharp {
                    fluid(maxWidth: 1200) {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
                }
            }
        }
    `);

    const logoItems = {};    

    data.allFile.edges.map(item =>{
        logoItems.[item.node.name] = item;
    });

    return  (
    <PartnerNotASupplierWrapper className="pt-0 pt-5 pb-5">
        <Container className="mb-5 px-3">
            <Row className="mx-0">
                <Col xs={12} className="text-center">
                    <h2 className="h1 ambit font-weight-bold primary-blue-text">Leading the payments industry</h2>
                    
                </Col>
                <Col lg={9} xs={12} md={10} className="mx-auto text-center">
                    <p className="intro-text my-4">We have developed an extensive partnership network and we’re keen to explore your involvement in this journey.</p>
                </Col>
            </Row>
            <Row className="logos justify-content-center mx-0">
                <Col lg md={4} sm={6} className="d-flex mt-5">
                    <img src={logoItems.sapCertified.node.childImageSharp.fluid.src} style={{width: "200px", display: "block", margin: "auto"}} alt={logoItems.sapCertified.node.name} />
                </Col>
                <Col lg md={4} sm={6} className="d-flex mt-5">
                    <img src={logoItems.cardstream.node.childImageSharp.fluid.src} style={{width: "125px", display: "block", margin: "auto"}} alt={logoItems.cardstream.node.name} />
                </Col>
                <Col lg md={4} sm={6} className="d-flex mt-5">
                    <img src={logoItems.cyberSource.node.childImageSharp.fluid.src} style={{width: "150px", display: "block", margin: "auto"}} alt={logoItems.cyberSource.node.name} />
                </Col>
                <Col lg md sm={6} className="d-flex mt-5">
                    <img src={logoItems.rpgcGroup.node.childImageSharp.fluid.src} style={{width: "125px", display: "block", margin: "auto"}} alt={logoItems.rpgcGroup.node.name} />
                </Col>
                <Col lg md sm={6} className="d-flex mt-5">
                    <img src={logoItems.ppro.node.childImageSharp.fluid.src} style={{width: "100px", display: "block", margin: "auto"}} alt={logoItems.ppro.node.name} />
                </Col>
            </Row>
        </Container>
    </PartnerNotASupplierWrapper>
)};

export default PartnerNotASupplier;