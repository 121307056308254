import React from "react";
import { graphql, useStaticQuery } from "gatsby";

const HowPartnershipsHelpImage = () => {

    const data = useStaticQuery(graphql`
        {
            allFile(filter: {relativeDirectory: {eq: "partnerships/HowPartnershipsHelp"}, name: {eq: "HowPartnershipsHelp"}}) {
                edges {
                node {
                    childImageSharp {
                        fluid(maxWidth: 1200) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                }
            }
        }
    `)

    return <img 
        src={data.allFile.edges[0].node.childImageSharp.fluid.src}
        alt="How Partnerships Help" 
        loading="lazy"
        className="ml-auto d-block help-image" 
        />
}

export default HowPartnershipsHelpImage