import styled from "styled-components"

export const SideBarWrapper = styled.div`
  .card-title {
    font-size:1.5rem;
  }
  .contact-image {
    width:15%;
  }
  .card-text {
    margin-bottom: unset;
  }
`
