import React from "react";
import { OurPartnerPackageWrapper } from "./OurPartnerPackage.styles";
import { Col,Container,Row } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";


const OurPartnerPackage = () => {

    const data = useStaticQuery(graphql`
        {
            allFile(filter: {relativeDirectory: {eq: "partnerships/OurPartnerPackage"}}) {
                edges {
                node {
                    name
                    childImageSharp {
                    fluid(maxWidth: 1200) {
                        ...GatsbyImageSharpFluid
                    }
                    }
                }
                }
            }
        }
    `);

    const imageItems = {};    

    data.allFile.edges.map(item =>{
        imageItems.[item.node.name] = item;
    });


    return(
    <OurPartnerPackageWrapper className="pb-5 px-3">
        <img src={imageItems.OurPartnerPackageLeadImage.node.childImageSharp.fluid.src} alt={imageItems.OurPartnerPackageLeadImage.node.name} className="lead-image" />
        <Container className="pb-lg-5 mb-lg-5 pt-3 text-white">
            <Row className="justify-content-center mx-0">
                <Col xs={12}>
                    <h2 className="h1 ambit color-sky-blue mx-auto font-weight-bold text-center color-sky-blue">The Partnerships Landscape</h2>
                </Col>
                <Col lg={8}>
                    <p className="text-white mx-auto mt-3 text-center intro-text">We want to help you go the extra mile. As an APEXX partner you’ll be entitled to:</p>
                </Col>
            </Row>
            <Row className="key-points pb-md-5 mx-0">
                <Col lg={4} md={6} xs={12} className="text-center mt-5">
                    <img src={imageItems.people.node.childImageSharp.fluid.src} className="mb-4" style={{maxWidth: "40px", display: "block", margin: "auto"}} alt={imageItems.people.node.name} />
                    <p className="text-white mx-auto" style={{maxWidth: '290px'}}>Designated Account Management</p>
                </Col>
                <Col lg={4} md={6} xs={12} className="text-center mt-5">
                    <img src={imageItems.info.node.childImageSharp.fluid.src} className="mb-4" style={{maxWidth: "40px", display: "block", margin: "auto"}} alt={imageItems.info.node.name} />
                    <p className="text-white mx-auto">Sales and Marketing Tools</p>
                </Col>
                <Col lg={4} md={6} xs={12} className="text-center mt-5">
                    <img src={imageItems.invertProcess.node.childImageSharp.fluid.src} className="mb-4" style={{maxWidth: "40px", display: "block", margin: "auto"}} alt={imageItems.invertProcess.node.name} />
                    <p className="text-white mx-auto">Access to our Partner Network (Potential)</p>
                </Col>
                <Col lg={4} md={6} xs={12} className="text-center mt-5">
                    <img src={imageItems.bulletList.node.childImageSharp.fluid.src} className="mb-4" style={{maxWidth: "40px", display: "block", margin: "auto"}} alt={imageItems.bulletList.node.name} />
                    <p className="text-white mx-auto" style={{maxWidth: '250px'}}>Business Development Plans</p>
                </Col>
                <Col lg={4} md={6} xs={12} className="text-center mt-5">
                    <img src={imageItems.growthArrow.node.childImageSharp.fluid.src} className="mb-4" style={{maxWidth: "40px", display: "block", margin: "auto"}} alt={imageItems.growthArrow.node.name} />
                    <p className="text-white mx-auto" style={{maxWidth: '200px'}}>Financial Incentives (Potential)</p>
                </Col>
                <Col lg={4} md={6} xs={12} className="text-center mt-5">
                    <img src={imageItems.headset.node.childImageSharp.fluid.src} className="mb-4" style={{maxWidth: "40px", display: "block", margin: "auto"}} alt={imageItems.headset.node.name} />
                    <p className="text-white mx-auto" style={{maxWidth: '225px'}}>Information, Guidance, and Support</p>
                </Col>
            </Row>
        </Container>
    </OurPartnerPackageWrapper>
)};

export default OurPartnerPackage;